//spécificités listing Agenda
parseCardAgendaClickOverlay();

// OVERLAY MAP
function callbackMapOverlayMap(macarte) {
    macarte.zoomControl.setPosition('topleft');
}

// listing Opérations - uniquement une map en overlay
var listingCPT = new thListingManager({
    selectors: {
        listing: '.js-listing-cpt',
        mapoverlay: '.js-listing-overlay-map',
    },
    mapOptions: {
        onlyDesktop: true,
    },
    mapOverlayOptions: {
        onlyMobile: false,
    },
    afterUpdate: function () {
    }
});

/* A l'ouverture de l'overlay, on charge la carte */
// cette fonction doit $etre déclarée avant le th_overlay.init()
th_overlay.addCallbackOpen(function (overlayId) {
    if (overlayId == 'overlay-map') {
        setTimeout(function () {
    console.log(overlayId);
            if (listingCPT.loaded) {
                listingCPT.initMapOverlay();
            }
        }, 250);
    }
});



/* Initialisation du slider de la carte */
(function () {
    setTimeout(function () {

        // Init des sliders
        const sliders = document.querySelectorAll('.js-slider-overlay-map');
        sliders.forEach(function(slider) {
            initSlider(slider);
        });

        function initSlider(slider) {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false
                    },
                }
            }).mount();
        }

    }, 550);
})();
