$('[data-anchor]').each(function () {
    $(this).on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var anchor = $(this).attr('data-anchor');
        if (anchor == 'scroll') {
            $('html, body').animate({scrollTop: ($('html,body').scrollTop() + document.body.clientHeight - 160)}, 'slow');
        } else {
            $('html, body').animate({scrollTop: ($($(this).attr('data-anchor')).offset().top - 120)}, 'slow');
        }
        return false;
    });
});
